<template>
	<footer>
		<div class="footer_content">
			<!--<span class="author">Avtorji: Peter Hrvatin, Miha Matjašič, Uroš Podkrižnik</span>-->
            <div class="version">Verzija: {{ getVersion }}</div>
            <div class="contact">
                <div>Kontakt: <a href="mailto:porocanje@uni-lj.si">porocanje@uni-lj.si</a></div>
                <div v-html="updateNavodilaText()" v-show="updateNavodilaText().length > 0"></div>
            </div>
            <div class="licence">
                <div class="licence_images">
                    <img src="@/assets/img/licence/cc.png" alt="" style="margin-right:2px;"/>
                    <img src="@/assets/img/licence/by.png" alt=""/>
                </div>
                <a href="https://creativecommons.org/licenses/by/4.0/deed.sl">Licencirano po CC BY 4.0</a>
            </div>
		</div>
	</footer>
</template>


<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Footer',
        data: function() {
            return {
                
            }
        },
        watch: {
            $route() {
                // React to route changes and update accordingly
                this.updateNavodilaText();
            }
        },
        computed: {
            // Get app version
			getVersion(){
				return this.$store.getters.getVersion;
            },
        },
        methods: { 		
            ...mapActions([
                'prepareVersion'
            ]),

            updateNavodilaText(){
                const currentRoutePath = this.$route.matched[0]?.path || '';

                if(currentRoutePath == '/program-dela') {
                    return 'Dodatne informacije&nbsp;in <a href="https://intranetul.uni-lj.si/Kakovost/Dokumentacija/Navodila%20za%20uporabo%20aplikacije%20za%20poro%C4%8Danje%20UL/Aplikacija_LPDinPP_Navodila_za_uporabo.docx" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://intranetul.uni-lj.si/Kakovost/Dokumentacija/Navodila%2520za%2520uporabo%2520aplikacije%2520za%2520poro%25C4%258Danje%2520UL/Aplikacija_LPDinPP_Navodila_za_uporabo.docx&amp;source=gmail&amp;ust=1733142226148000&amp;usg=AOvVaw1_-UXgmmXBI4RFzjRT2xD8"> navodila za uporabo</a> najdete na <a href="https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx#/Priprava%20programa%20dela" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx%23/Priprava%2520programa%2520dela&amp;source=gmail&amp;ust=1733142226148000&amp;usg=AOvVaw1H5MReQfhEM5ZOeKUf_1sI">povezavi</a>.<u></u><u></u>';
                } else if(currentRoutePath == '/podrocja') {
                    return 'Dodatne informacije in <a href="https://intranetul.uni-lj.si/Kakovost/Dokumentacija/Navodila%20za%20uporabo%20aplikacije%20za%20poro%C4%8Danje%20UL/Aplikacija_LPDinPP_Navodila_za_uporabo.docx" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://intranetul.uni-lj.si/Kakovost/Dokumentacija/Navodila%2520za%2520uporabo%2520aplikacije%2520za%2520poro%25C4%258Danje%2520UL/Aplikacija_LPDinPP_Navodila_za_uporabo.docx&amp;source=gmail&amp;ust=1733142226148000&amp;usg=AOvVaw1_-UXgmmXBI4RFzjRT2xD8">navodila za uporabo</a> najdete na <a href="https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx#/SitePages/Poslovno%20poro%C4%8Dilo.aspx" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx%23/SitePages/Poslovno%2520poro%25C4%258Dilo.aspx&amp;source=gmail&amp;ust=1733142226148000&amp;usg=AOvVaw09S3OxzVMDBcnPHUrRJ63y">povezavi</a>.<u></u><u></u>';
                } else if(currentRoutePath == '/samoevalvacija') {
                    return 'Dodatne informacije&nbsp;in navodila za uporabo najdete na <a href="https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx#/Samoevalvacija%20P1" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://intranetul.uni-lj.si/Kakovost/_layouts/15/start.aspx%23/Samoevalvacija%2520P1&amp;source=gmail&amp;ust=1733142226148000&amp;usg=AOvVaw3351Xt9mLFOIAjSYzwYIu5">povezavi</a>.<u></u><u></u>';
                } else {
                    return '';
                }
            }
        },
        created(){
            this.prepareVersion();
		},
    }
</script>


<style lang="scss" scoped>
	@import "../scss/style.scss";
</style>
